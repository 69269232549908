import React, { ReactElement } from "react";
import styles from "./styles.module.scss";
import ScrollToTop from "@components/shared/scrollToTop";

export default function BottomSection(): ReactElement {
    return (
        <section className={`mt-10 lg:mt-16 ${styles["bottomSection"]} `}>
            <div className={`${styles["bottomSectionWrapper"]} text-center`}>
                <div className="max-w-7xl mx-auto block px-4vw lg:px-4">
                    <h2
                        className={`${styles["title"]} font-extrabold text-2xl md:text-4xl`}
                    >
                        Is There an Upfront Fee to File a Lawsuit?
                    </h2>
                    <p className={`${styles["brief"]} text-base md:text-xl`}>
                        We work on a contingency basis, meaning we get paid if
                        you win your case.
                    </p>
                </div>
                <div className="flex justify-center items-center   mt-8 ">
                    <ScrollToTop
                        colors={{
                            bg: "#be1e20",
                            text: "white",
                        }}
                        className="rounded"
                    >
                        Get My Free Lawsuit Case Review
                    </ScrollToTop>
                </div>
            </div>
        </section>
    );
}
